import axios from "axios";

const API_BASE = 'https://lbox.one/api';

const post = (path, body) =>
    axios({
        headers: {
            "Content-Type": "application/json",
        },
        method: "post",
        url: `${API_BASE}${path}`,
        data: body ? JSON.stringify(body) : undefined,
    });


export default {
    POST: post
}
