/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKBadge from "../../../components/MKBadge";
import MKTypography from "../../../components/MKTypography";

// Presentation page components
import ExampleCard from "../../../pages/Presentation/components/ExampleCard";

// Data
import data from "../../../pages/Presentation/sections/data/pagesData";

function What() {
    const renderData = data.map(({ image, name, route }) => (
        <Grid item xs={12} md={6} sx={{ mb: { xs: 3, lg: 0 } }} key={name}>
            <Link to={route}>
                <ExampleCard image={image} name={name} display="grid" minHeight="auto" />
            </Link>
        </Grid>
    ));

    return (
        <MKBox component="section" py={6}>
            <Container>
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    flexDirection="column"
                    alignItems="center"
                    sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
                >
                    <MKTypography variant="h2" fontWeight="bold">
                        Что это и зачем это необходимо?
                    </MKTypography>
                    <MKTypography variant="body1" color="text">
                        Успешность многих бизнесов сегодня напрямую зависит от качества данных, от их сохранности и уверенности в их неизменности.
                        Несанкционированный доступ, корректировки и изменения задним числом, подмена данных, удаление данных -- вот неполный список проблем, с которыми часто сталкиваются компании, чья деятельность связана с обработкой данных, в том числе такой чувствительной информации как персональные данные или финансовые транзакции.
                         &nbsp;<strong>LBoxOne</strong> предлагает способ обеспечить неизменность данных о событиях в информационных системах, а в случае появляения сомнений в их достоверности, проверить их на валидность.
                    </MKTypography>
                </Grid>
            </Container>
            {/*<Container sx={{ mt: { xs: 8, lg: 16 } }}>*/}
            {/*    <Grid container spacing={3}>*/}
            {/*        <Grid item xs={12} lg={9} sx={{ mt: 3, px: { xs: 0, lg: 8 } }}>*/}
            {/*            <Grid container spacing={3}>*/}
            {/*                {renderData}*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*        <Grid item xs={12} lg={3}>*/}
            {/*            <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>*/}
            {/*                <MKTypography variant="h3" fontWeight="bold" mb={1}>*/}
            {/*                    Presentation Pages for Company, Landing Pages, Blogs and Support*/}
            {/*                </MKTypography>*/}
            {/*                <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>*/}
            {/*                    These is just a small selection of the multiple possibitilies you have. Focus on the*/}
            {/*                    business, not on the design.*/}
            {/*                </MKTypography>*/}
            {/*            </MKBox>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Container>*/}
        </MKBox>
    );
}

export default What;
