import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

import TariffCard from "../../../examples/Cards/TariffCard";
import React, {useState} from "react";
import SubscribeModal from "../modals/SubscribeModal";

function Tariffs() {
    const [show, setShow] = useState(false);
    const [tariff, setTariff] = useState('');
    const openModal = (inputTariff) => {
        setTariff(inputTariff);
        toggleModal();
    }
    const toggleModal = () => setShow(!show);

    return (
        <>
            <SubscribeModal
                show={show}
                toggleCallback={openModal}
                tariff={tariff}
            />
        <MKBox component="section"
               id={"tariffs"}
               // variant={'gradient'}
               py={6} bgColor={'light'}
               px={{ xs: 2, lg: 2 }}
               mx={-2}
        >
            <Container>
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    justifyContent="center"
                    sx={{ mx: "auto", textAlign: "center" }}
                >
                    <MKTypography variant="h2">Тарифы</MKTypography>

                </Grid>
                <Grid container spacing={3} sx={{ mt: 8 }}>

                        <Grid item xs={12} md={6} lg={4}>
                            <MKBox
                                bgColor={'white'}
                                borderRadius={"0px 0px 12px 12px"}
                            >
                                <TariffCard
                                    name="Демо"
                                    date="1 day ago"
                                    title={'Демо'}
                                    rating={5}
                                    settings={[
                                        {value: '14 дней', name: 'активен'},
                                        {value: '1', name: 'блокчейн'},
                                        {value: '1', name: 'агентов'},
                                        {value: '5 дней', name: 'глубина хранения'},
                                        {value: '<br/>200Mb или 1,000 транзакций', name: 'максимальный размер блокчейна'},
                                        {value: 'Email', name: ' поддержка'},
                                    ]}
                                    calculationUnit={'безлимитный'}
                                    price={0}
                                    clickCallback={openModal}
                                />
                            </MKBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <MKBox
                                bgColor={'white'}
                                borderRadius={"0px 0px 12px 12px"}
                            >
                                <TariffCard
                                    name="Бизнес"
                                    date="1 day ago"
                                    title={'Бизнес'}
                                    bgColor={"info"}
                                    rating={5}
                                    settings={[
                                        {value: 'по подписке', name: 'активен'},
                                        {value: '1', name: 'блокчейн'},
                                        {value: 'до 10', name: 'агентов'},
                                        {value: '180 дней', name: 'глубина хранения'},
                                        {value: '<br/>180Gb или 1 млрд. транзакций', name: 'максимальный размер блокчейна'},
                                        {value: 'Email', name: ' поддержка'},
                                    ]}
                                    calculationUnit={'/ месяц'}
                                    price={4500}
                                    clickCallback={openModal}

                                />
                            </MKBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <MKBox
                                bgColor={'white'}
                                borderRadius={"0px 0px 12px 12px"}

                            >
                                <TariffCard
                                    name="Премиум"
                                    date="1 day ago"
                                    review=""
                                    title={'Премиум'}
                                    rating={5}
                                    settings={[
                                        {value: 'по подписке', name: 'активен'},
                                        {value: 'более 1', name: 'блокчейн'},
                                        {value: 'по запросу', name: 'агентов'},
                                        {value: 'по запросу', name: 'глубина хранения'},
                                        {value: '<br/>по запросу', name: 'максимальный размер блокчейна'},
                                        {value: 'Email, чат', name: ' поддержка'},
                                    ]}
                                    calculationUnit={'/ месяц'}
                                    price={"по запросу"}
                                    clickCallback={openModal}
                                />
                            </MKBox>
                        </Grid>

                </Grid>

            </Container>
        </MKBox>
        </>
    );
}

export default Tariffs;
