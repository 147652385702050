/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {useEffect, useRef, useState} from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKButton from "../../../components/MKButton";
import MKTypography from "../../../components/MKTypography";
import * as API from "../../../api/api";
import MKInput from "../../../components/MKInput";

const SubscribeModal = ({show, toggleCallback, tariff}) => {

    const [isSending, setIsSending] = useState(false);
    const [sent, setSent] = useState(false);
    const [hasError, setHasError] = useState(null);
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [company, setCompany] = useState('');
    const [nameRequired, setNameRequired] = useState(false);
    const [contactRequired, setContactRequired] = useState(false);
    const [companyRequired, setCompanyRequired] = useState(false);
    let timerRef = useRef(null);

    useEffect(() => {return () => clearTimeout(timerRef.current)},
        [isSending]);

    const sendMessage = async (e) => {
        setIsSending(true);
        let error = false;
        if (!name) {
            setNameRequired(true);
            error = true;
        }
        if (!contact) {
            setContactRequired(true);
            error = true;
        }
        if (!company) {
            setCompanyRequired(true);
            error = true;
        }
        if (error) {
            setHasError(true);
            setIsSending(false);
            return;
        }

        const response = await API.requestSubscription(
            {name: name, contactInfo: contact, company: company, tariff: tariff}
        );
        setHasError(response.isError);
        setIsSending(false)
        setSent(true);
        toggleCallback('');
        timerRef = setTimeout(() => {
            setSent(false);
        }, 4000);
    }


    return (
        <MKBox component="section" py={6}>
            <Container>
                <Modal open={show}
                       onClose={() => toggleCallback('')}
                       sx={{ display: "grid", placeItems: "center" }}>
                    <Slide direction="down" in={show} timeout={500}>
                        <MKBox
                            position="relative"
                            width="500px"
                            display="flex"
                            flexDirection="column"
                            borderRadius="xl"
                            bgColor="white"
                            shadow="xl"
                        >
                            <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                                <MKTypography variant="h5">Запросить подписку на тариф {tariff}</MKTypography>
                            </MKBox>
                            <Divider sx={{ my: 0 }} />
                            <MKBox p={2}>
                                <Grid item xs={12} pr={1} mb={6}>
                                    <MKInput
                                        onInput={e => {
                                            setHasError(false);
                                            setNameRequired(false);
                                            setName(e.target.value);
                                        }}
                                        value={name}
                                        variant="standard"
                                        error={nameRequired}
                                        required={true}
                                        label="Как к Вам обращаться?"
                                        placeholder="Имя"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        name={"name"}
                                    />
                                </Grid>
                                <Grid item xs={12} pr={1} mb={6}>
                                    <MKInput
                                        onInput={e => {
                                            setHasError(false);
                                            setCompanyRequired(false);
                                            setCompany(e.target.value);
                                        }}
                                        value={company}
                                        variant="standard"
                                        error={companyRequired}
                                        required={true}
                                        label="Как называется Ваша компания?"
                                        placeholder="Название компании"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        name={"company"}
                                    />
                                </Grid>
                                <Grid item xs={12} pr={1} mb={6}>
                                    <MKInput
                                        onInput={e => {
                                            setHasError(false);
                                            setContactRequired(false);
                                            setContact(e.target.value);
                                        }}
                                        value={contact}
                                        variant="standard"
                                        error={contactRequired}
                                        required={true}
                                        label="Как с Вами связаться?"
                                        placeholder="Любой контакт"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        name={"contactInfo"}
                                    />
                                </Grid>
                            </MKBox>
                            <Divider sx={{ my: 0 }} />
                            <MKBox display="flex" justifyContent="space-between" p={1.5}>
                                <MKButton variant="gradient" color="dark" onClick={() => toggleCallback('')}>
                                    Отменить
                                </MKButton>
                                <MKButton variant="gradient" color="info"
                                    onClick={() => sendMessage()}
                                >
                                    Отправить
                                </MKButton>
                            </MKBox>
                        </MKBox>
                    </Slide>
                </Modal>
            </Container>
        </MKBox>
    );
}

export default SubscribeModal;
