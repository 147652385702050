/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "../../../examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import fintech from "../../../assets/images/fintech.jpeg";
import acms from "../../../assets/images/acms.jpeg";
import iot from "../../../assets/images/iot.jpeg";
import manufacturing from "../../../assets/images/manufacturing.jpeg";
import delivery from "../../../assets/images/delivery.jpeg";
import pharma from "../../../assets/images/pharma.jpeg";
import React from "react";

function Usages() {
    return (
        <MKBox
            component="section"
            variant="gradient"
            bgColor="dark"
            position="relative"
            py={6}
            px={{ xs: 2, lg: 0 }}
            mx={-2}
        >
            <Container>
                <Grid container>
                    <Grid item xs={12} md={8} sx={{ mb: 6 }}>
                        <MKTypography variant="h3" color="white">
                            Для кого это подходит?
                        </MKTypography>
                        <MKTypography variant="body2" color="white" opacity={0.8}>
                             Гарантия неизменности данных о произошедших событиях может быть полезна в различных сферах
                             и отраслях
                        </MKTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <MKBox mb={1}>
                            <HorizontalTeamCard
                                image={fintech}
                                name="Финансовые организации"
                                position={{ color: "info", label: "FinTech" }}
                                description="хранение неизмяемой истории финансовых транзакций"
                            />
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MKBox mb={1}>
                            <HorizontalTeamCard
                                image={acms}
                                name="Службы безопасности"
                                position={{ color: "info", label: "Security" }}
                                description="хранение событий информационных систем и их защита от изменений"
                            />
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MKBox mb={{ xs: 1, lg: 0 }}>
                            <HorizontalTeamCard
                                image={iot}
                                name="Разработчики сервисов IoT"
                                position={{ color: "info", label: "IoT" }}
                                description="логирование событий обмена данными в IoT проектах"
                            />
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MKBox mb={{ xs: 1, lg: 0 }}>
                            <HorizontalTeamCard
                                image={manufacturing}
                                name="Промышленные предприятия"
                                position={{ color: "info", label: "Industry" }}
                                description="хранение логов событий и операций промышленного оборудования любой сложности"
                            />
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MKBox mb={{ xs: 1, lg: 0 }}>
                            <HorizontalTeamCard
                                image={delivery}
                                name="Логистические компании"
                                position={{ color: "info", label: "Logistics" }}
                                description="надежное отслеживание логистических цепочек поставок компонентов"
                            />
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MKBox mb={{ xs: 1, lg: 0 }}>
                            <HorizontalTeamCard
                                image={pharma}
                                name="Фармацевтические компании"
                                position={{ color: "info", label: "Pharma" }}
                                description="отслеживание происхождения ингридиентов, условий их транспортировки и хранения"
                            />
                        </MKBox>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Usages;
