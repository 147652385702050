import MyAxios from "./MyAxios";

export const sendMessage = async ({
    name, contactInfo, message
}) => {
    let isError = false;
    const data = {
        name: name, contactInfo: contactInfo, message: message
    }
    await MyAxios
        .POST(`/api/v1/send-message`, data).then(()=> {})
        .catch(e => {
            isError = true
        });
    return {
        isError: isError
    }
};
export const requestSubscription = async ({
    name, contactInfo, company, tariff
}) => {
    let isError = false;
    const data = {name: name, contactInfo: contactInfo, company: company, tariff: tariff}
    await MyAxios
        .POST(`/api/v1/get-subscription`, data).then(()=> {})
        .catch(e => {
            isError = false
        });
    return {
        isError: isError
    }
};

export const login = async ({username, password}) => {
    let isError = false;
    const data = {username: username, password: password}
    const response = await MyAxios
        .POST(`/token/`, data)
        .catch(e => {
            isError = false
        });
    console.log(response);
    return {
        isError: isError,
        response: response.data
    }
}
