/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import React from "react";
import MKButton from "../../../components/MKButton";

function TariffCard({ color, icon, title,
                        bgColor,
                        description, direction, small, settings, price, calculationUnit,
                        clickCallback}) {
    const broken = (name, value) => `&{name} - <br/> ${value}`;
    return (
        <>
            <MKBox
                borderRadius={"12px 12px 0px 0px"}
                color={'white'}
                lineHeight={1} p={2}
                   bgColor={bgColor}
                   textAlign={'center'}>
            {typeof icon === "string" ? (
                <MKTypography
                    display="block"
                    variant={direction === "center" ? "h2" : "h3"}
                    color={color}
                    textGradient
                >
                    {" "}
                    <Icon>{icon}</Icon>{" "}
                </MKTypography>
            ) : (
                icon
            )}
            <MKTypography
                display="block"
                fontSize={'2rem'}
                variant="5"
                fontWeight="bold"
                mt={direction === "center" ? 1 : 2}
                mb={1.5}
                color={'white'}
            >
                {title}
            </MKTypography>
                <hr />
            <MKTypography
                display="block"
                variant={small ? "button" : "body2"}
                color="white"
                fontSize={"3rem"}
                fontWeight={"bold"}
                // pr={direction === "left" ? 6 : 0}
                // pl={direction === "right" ? 6 : 0}
            >
                {isNaN(price) ? price : `${price} ₽`}
            </MKTypography>
            <MKTypography
                display="block"
                variant={small ? "button" : "body2"}
                color="white"
                // pr={direction === "left" ? 6 : 0}
                // pl={direction === "right" ? 6 : 0}
            >
                {calculationUnit}
            </MKTypography>

        </MKBox>
            <MKBox
                lineHeight={1}
                p={2}
                textAlign={'center'}
                pb={4}
                minHeight={"20rem"}
            >
                {settings.map(({name, value}) =>
                    <MKTypography
                        display="block"
                        variant={small ? "button" : "body2"}
                        color="text"
                        pt={"5px"}
                        // pr={direction === "left" ? 6 : 0}
                        // pl={direction === "right" ? 6 : 0}
                        dangerouslySetInnerHTML={{__html:  `${name} - ${value}`}}
                    >
                        {/*{name.includes('размер') ? broken(name, value) : `${name} - ${value}`}*/}
                    </MKTypography>
                )}
                <MKBox
                    mt={3}
                >
                    <MKButton
                        mt={'auto'}
                        color={bgColor}
                        onClick={() => clickCallback(title)}
                    >Заказать</MKButton>
                </MKBox>
            </MKBox>

        </>
    );
}

// Setting default props for the DefaultInfoCard
TariffCard.defaultProps = {
    color: "info",
    direction: "left",
    small: false,
    bgColor: "dark"
};

// Typechecking props for the DefaultInfoCard
TariffCard.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    bgColor: PropTypes.string,
    icon: PropTypes.node,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    price: PropTypes.string.isRequired,
    calculationUnit: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(["left", "right", "center"]),
    small: PropTypes.bool,
    settings: PropTypes.array.isRequired
};

export default TariffCard;
