
// Features page components
import FeaturesOne from "../../../layouts/sections/page-sections/featuers/components/FeaturesOne";
import screenshot from "../../../assets/images/screenshot.jpg";
// Features page components code
import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import Icon from "@mui/material/Icon";
import CenteredBlogCard from "../../../examples/Cards/BlogCards/CenteredBlogCard";

function DataManagement() {
    return (
        <MKBox component="section" py={{ xs: 3, md: 12 }}>
            <Container>
                <Grid container alignItems="center">
                    <Grid item xs={12} lg={5}>
                        <MKTypography variant="h3" my={1}>
                            Управление данными
                        </MKTypography>
                        <MKTypography variant="body2" color="text" mb={2}>
                            LBoxOne предоставляет доступ к данным, хранящимся в Вашем блокчейне по АПИ, а также в интерфейсе личного кабинета.
                        </MKTypography>
                        <MKTypography
                            component="a"
                            href="#"
                            variant="body2"
                            color="info"
                            fontWeight="regular"
                            sx={{
                                width: "max-content",
                                display: "flex",
                                alignItems: "center",

                                "& .material-icons-round": {
                                    fontSize: "1.125rem",
                                    transform: "translateX(3px)",
                                    transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                                },

                                "&:hover .material-icons-round, &:focus .material-icons-round": {
                                    transform: "translateX(6px)",
                                },
                            }}
                        >
                            Получить демо доступ
                            <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                        </MKTypography>
                    </Grid>
                    <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
                        <CenteredBlogCard
                            image={screenshot}
                            // title="Личный кабинет"
                            //description=""
                            // action={{
                            //     type: "internal",
                            //     route: "pages/company/about-us",
                            //     color: "info",
                            //     label: "find out more",
                            // }}
                        />
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default DataManagement;
