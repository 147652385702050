/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, {useEffect, useRef, useState} from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";

// @mui icons
import CloseIcon from "@mui/icons-material/Close";

// Material Kit 2 React components
import MKBox from "../MKBox";
import MKButton from "../MKButton";
import MKTypography from "../MKTypography";
import * as API from "../../api/api";
import MKInput from "../MKInput";
import axios from "axios";

const LoginModal = ({show, toggleCallback}) => {

    const [isSending, setIsSending] = useState(false);
    const [sent, setSent] = useState(false);
    const [hasError, setHasError] = useState(null);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [usernameRequired, setUsernameRequired] = useState(false);
    const [passwordRequired, setPasswordRequired] = useState(false);
    let timerRef = useRef(null);

    useEffect(() => {return () => clearTimeout(timerRef.current)},
        [isSending]);

    const sendLogin = async (e) => {
        setIsSending(true);
        let error = false;
        if (!username) {
            setUsernameRequired(true);
            error = true;
        }
        if (!password) {
            setPasswordRequired(true);
            error = true;
        }
        if (error) {
            setHasError(true);
            setIsSending(false);
            return;
        }

        const response = await API.login(
            {username: username, password: password}
        );
        console.log(response);
        localStorage.setItem('authtoken', response.response.access);
        axios.defaults.headers.common.Authorization = `bearer ${response.response.access}`;
        setHasError(response.isError);
        setIsSending(false)
        setSent(true);
        toggleCallback('');
        setUsername('');
        setPassword('');
        timerRef = setTimeout(() => {
            setSent(false);
        }, 4000);
    }


    return (
            <Modal open={show}
                   onClose={() => toggleCallback('')}
                   sx={{ display: "grid", placeItems: "center" }}>
                    <Slide direction="down" in={show} timeout={500}>
                        <MKBox
                            position="relative"
                            width="500px"
                            display="flex"
                            flexDirection="column"
                            borderRadius="xl"
                            bgColor="white"
                            shadow="xl"
                        >
                            <MKBox display="flex" alginItems="center" justifyContent="space-between" p={2}>
                                <MKTypography variant="h5">Войти</MKTypography>
                            </MKBox>
                            <Divider sx={{ my: 0 }} />
                            <MKBox p={2}>
                                <Grid item xs={12} pr={1} mb={6}>
                                    <MKInput
                                        onInput={e => {
                                            setHasError(false);
                                            setUsernameRequired(false);
                                            setUsername(e.target.value);
                                        }}
                                        value={username}
                                        variant="standard"
                                        error={usernameRequired}
                                        required={true}
                                        label="Логин"
                                        placeholder=""
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        name={"name"}
                                    />
                                </Grid>
                                <Grid item xs={12} pr={1} mb={6}>
                                    <MKInput
                                        type={'password'}
                                        onInput={e => {
                                            setHasError(false);
                                            setPasswordRequired(false);
                                            setPassword(e.target.value);
                                        }}
                                        value={password}
                                        variant="standard"
                                        error={passwordRequired}
                                        required={true}
                                        label="Пароль"
                                        placeholder="***"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        name={"company"}
                                    />
                                </Grid>

                            </MKBox>
                            <Divider sx={{ my: 0 }} />
                            <MKBox display="flex" justifyContent="space-between" p={1.5}>
                                <MKButton variant="gradient" color="dark" onClick={() => toggleCallback('')}>
                                    Отменить
                                </MKButton>
                                <MKButton variant="gradient" color="info"
                                    onClick={() => sendLogin()}
                                >
                                    Войти
                                </MKButton>
                            </MKBox>
                        </MKBox>
                    </Slide>
                </Modal>

    );
}

export default LoginModal;
