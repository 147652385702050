import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import MKBox from "../../../components/MKBox";

import DefaultInfoCard from "../../../examples/Cards/InfoCards/DefaultInfoCard";

import React from "react";
import MKTypography from "../../../components/MKTypography";

function Information() {
  return (
    <MKBox
        component="section" py={6} my={6}
        id={'how'}
    >
      <Container>
            <Grid
                container
                item
                xs={12}
                lg={6}
                flexDirection="column"
                alignItems="center"
                sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
            >
              <MKTypography variant="h2" fontWeight="bold">
                Как это работает?
              </MKTypography>
            </Grid>
        <Grid container item xs={12} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={12} sx={{ ml: "auto" }} >
            <Grid container spacing={3}   justifyContent="center">
              <Grid item xs={12} md={3}>
                <DefaultInfoCard
                  icon="link"
                  title="1. Настройка приватного блокчейна"
                  description="После регистрации в системе, вы получаете свой собственный приватный блокчейн проект и доступы к нему."
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DefaultInfoCard
                  icon="app_registration"
                  title="2. Регистрация агентов"
                  description="Вы регистрируете ваши системы (агенты), отправляющие события."
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <DefaultInfoCard
                    icon="send"
                    title="3. Отправка событий"
                    description="Ваши системы (агенты) начинают отправку событий в Ваш приватный блокчейн."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} md={3} sx={{marginTop: "1rem"}}>
                <DefaultInfoCard
                  icon="move_to_inbox"
                  title="4. Получение событий"
                  description="LBoxOne получает запрос с данными о событии, добавляет их в очередь обработки транзакции и возвращает ее идентификатор. По этому идентификактору можно найти адрес блока, в который попала транзакция, а также посмотреть ее содержимое."
                />
              </Grid>
              <Grid item xs={12} md={3} sx={{marginTop: "1rem"}}>
                <DefaultInfoCard
                    icon="account_tree"
                    title="5. Обработка транзакций"
                    description="Транзакции группируются в блоки, которые в свою очередь составляются в закрытую цепочку. Каждая из которых затем присоединяется к новому Метаблоку."
                />
              </Grid>
              <Grid item xs={12} md={3} sx={{marginTop: "1rem"}}>
                <DefaultInfoCard
                    icon="beenhere"
                    title="6. Проверка валидности данных"
                    description="Ваши системы отправляют запросы на проверку валидности данных событий и получают
                    ответы об их валидности/невалидности при сравнении с эталоном, хранящимся в блокчейне."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={12} md={3} sx={{marginTop: "1rem"}}>
                <DefaultInfoCard
                    icon="devices"
                    title="7. Просмотр данных*"
                    description="Вы просматриваете данные в логах, хранящихся в блокчейне. *опция доступна в спец тарифах"
                />
              </Grid>
            </Grid>
          </Grid>
      </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
