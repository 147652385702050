/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "../../../components/MKBox";
import MKInput from "../../../components/MKInput";
import MKButton from "../../../components/MKButton";
import MKTypography from "../../../components/MKTypography";
import TelegramIcon from '@mui/icons-material/Telegram';
import * as API from '../../../api/api';
// Images
import bgImage from "../../../assets/images/examples/blog2.jpg";
import React, {useEffect, useRef, useState} from "react";

function Contact() {

    const [isSending, setIsSending] = useState(false);
    const [sent, setSent] = useState(false);
    const [hasError, setHasError] = useState(null);
    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [message, setMessage] = useState('');
    const [nameRequired, setNameRequired] = useState(false);
    const [contactRequired, setContactRequired] = useState(false);
    const [messageRequired, setMessageRequired] = useState(false);
    let timerRef = useRef(null);

    useEffect(() => {return () => clearTimeout(timerRef.current)},
    [isSending]);

     const sendMessage = async (e) => {
        setIsSending(true);
        let error = false;
        if (!name) {
            setNameRequired(true);
            error = true;
        }
         if (!contact) {
             setContactRequired(true);
             error = true;
         }
         if (!message) {
             setMessageRequired(true);
             error = true;
         }
         if (error) {
             setHasError(true);
             setIsSending(false);
             return;
         }

        const response = await API.sendMessage(
             {name: name, contactInfo: contact, message: message}
         );
         setHasError(response.isError);
         setIsSending(false)
         setSent(true);
         timerRef = setTimeout(() => {
            setSent(false);
         }, 4000);
    }
    return (
        <MKBox
            id={"contact-us"}
            bgColor={'white'}
            mx={-2}
            component="section" py={{ xs: 0, lg: 6 }}>
            <Container>
                <Grid container item>
                    <MKBox
                        width="100%"
                        bgColor="white"
                        borderRadius="xl"
                        shadow="xl"
                        mb={6}
                        sx={{ overflow: "hidden" }}
                    >
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                lg={5}
                                position="relative"
                                px={0}
                                sx={{
                                    backgroundImage: ({
                                                          palette: { gradients },
                                                          functions: { rgba, linearGradient },
                                                      }) =>
                                        `${linearGradient(
                                            rgba(gradients.dark.main, 0.8),
                                            rgba(gradients.dark.state, 0.8)
                                        )}, url(${bgImage})`,
                                    backgroundSize: "cover",
                                }}
                            >
                                <MKBox
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    width="100%"
                                    height="100%"
                                >
                                    <MKBox py={6} pr={6} pl={{ xs: 6, sm: 12 }} my="auto">
                                        <MKTypography variant="h3" color="white" mb={1}>
                                            Контактная информация
                                        </MKTypography>
                                        <MKTypography variant="body2" color="white" opacity={0.8} mb={3}>
                                            Если Вас заинтересовало наше ILaaS решение, напишите нам.
                                        </MKTypography>
                                        <MKBox display="flex" p={1}>
                                            <MKTypography variant="button" color="white">
                                                <i className="fa fa-telegram" />
                                            </MKTypography>
                                            <MKTypography
                                                component="span"
                                                variant="button"
                                                color="white"
                                                opacity={0.8}
                                                ml={2}
                                                fontWeight="regular"
                                            >
                                                @lboxone
                                            </MKTypography>
                                        </MKBox>
                                        <MKBox display="flex" color="white" p={1}>
                                            <MKTypography variant="button" color="white">
                                                <i className="fa fa-envelope" />
                                            </MKTypography>
                                            <MKTypography
                                                component="span"
                                                variant="button"
                                                color="white"
                                                opacity={0.8}
                                                ml={2}
                                                fontWeight="regular"
                                            >
                                                boxlinkone@gmail.com
                                            </MKTypography>
                                        </MKBox>
                                    </MKBox>
                                </MKBox>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                                <MKBox component="form" p={2} method="post">
                                    <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                                        <MKTypography variant="h2" mb={1}>
                                            Вас заинтересовало ILaaS решение?
                                        </MKTypography>
                                        <MKTypography variant="body1" color="text" mb={2}>
                                            Напишите нам об этом.
                                        </MKTypography>
                                    </MKBox>
                                    <MKBox pt={0.5} pb={3} px={3}>
                                        <Grid container>
                                            <Grid item xs={12} pr={1} mb={6}>
                                                <MKInput
                                                    onInput={e => {
                                                        setHasError(false);
                                                        setNameRequired(false);
                                                        setName(e.target.value);
                                                    }}
                                                    value={name}
                                                    variant="standard"
                                                    error={nameRequired}
                                                    required={true}
                                                    label="Как к Вам обращаться?"
                                                    placeholder="Имя"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    name={"name"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} pr={1} mb={6}>
                                                <MKInput
                                                    onInput={e => {
                                                        setHasError(false);
                                                        setContactRequired(false);
                                                        setContact(e.target.value);
                                                    }}
                                                    value={contact}
                                                    required={true}
                                                    error={contactRequired}
                                                    variant="standard"
                                                    label="Как с Вами связаться?"
                                                    placeholder="Любой контакт"
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    name={"contactInfo"}
                                                />
                                            </Grid>
                                            <Grid item xs={12} pr={1} mb={6}>
                                                <MKInput
                                                    onInput={e => {
                                                        setHasError(false);
                                                        setMessageRequired(false);
                                                        setMessage(e.target.value);
                                                    }}
                                                    value={message}
                                                    error={messageRequired}
                                                    required={true}
                                                    variant="standard"
                                                    label="Ваше сообщение"
                                                    placeholder=""
                                                    InputLabelProps={{ shrink: true }}
                                                    fullWidth
                                                    multiline
                                                    rows={6}
                                                    name={"message"}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            md={12}
                                            justifyContent="flex-end"
                                            textAlign="right"
                                            ml="auto"
                                        >
                                            <Grid
                                                ml="auto"
                                                display={'flex'}
                                                alignItems={'center'}
                                            >
                                                {sent &&
                                                    <div
                                                        style={{
                                                            color: hasError ? 'red' :'green',
                                                            fontSize: '0.9rem',
                                                            marginRight: "3rem"}}
                                                    >
                                                        {hasError ?
                                                           'Ошибка отправки сообщения. Попробуйте позже'
                                                            : 'Ваше сообщение отправлено'
                                                        }
                                                    </div>
                                                }
                                                <MKButton
                                                    disabled={hasError}
                                                    loading={isSending}
                                                    onClick={sendMessage}
                                                    variant={isSending ? "outlined" : "gradient"}
                                                    color="info">
                                                    Отправить
                                                </MKButton>
                                            </Grid>
                                        </Grid>
                                    </MKBox>
                                </MKBox>
                            </Grid>
                        </Grid>
                    </MKBox>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Contact;
