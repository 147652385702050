import MKTypography from "./components/MKTypography";

import logoCT from "./assets/images/logo-ct-dark.png";
import React from "react";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "LBoxOne",
    image: logoCT,
    route: "/",
  },
  socials: [

  ],
  menus: [
        {
      name: "",
      items: [

      ],
    },
    {
      name: "Компания",
      items: [
        { name: "О нас", href: "/about-us" },
        { name: "Тарифы", href: "/#tariffs" },
      ],
    },
    {
      name: "Поддержка",
      items: [
        { name: "Для разработчиков", href: "/documentation" },
        { name: "Для связи", href: "/#contact-us" },
      ],
    },
    {
      name: "Документы",
      items: [
        { name: "Условия использования", href: "/terms" },
        { name: "Политика конфиденциальности", href: "/privacy" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      &copy; {date} {" "}LBoxOne
    </MKTypography>
  ),
};
