import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKSocialButton from "../../components/MKSocialButton";

// Material Kit 2 React examples
import Navbar from "../../examples/Navbars/Navbar";



import mainRoutes from "../../main-routes";
import footerRoutes from "../../main-footer.routes";

// Images
import bgImage from "../../assets/images/bg-main-back.jpg";
import React from "react";
import Footer from "../Main/sections/Footer";

function UnderConstruction() {
    return (
        <>
            <Navbar
                routes={mainRoutes}
            />
            <MKBox
                minHeight="75vh"
                width="100%"
                sx={{
                    backgroundImage: `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    display: "grid",
                    placeItems: "center",
                }}
            >
                <Container>
                    <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
                        <MKTypography
                            variant="h1"
                            color="white"
                            mt={-6}
                            mb={1}
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                }, textAlign: 'center'
                            })}
                        >
                            Страница находится в разработке.
                        </MKTypography>

                    </Grid>
                </Container>
            </MKBox>
            <MKBox pt={6} px={1} mt={6}>
                <Footer content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default UnderConstruction;
