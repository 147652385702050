/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import MKSocialButton from "../../components/MKSocialButton";

// Material Kit 2 React examples
import Navbar from "../../examples/Navbars/Navbar";
import DefaultFooter from "../../examples/Footers/DefaultFooter";
import FilledInfoCard from "../../examples/Cards/InfoCards/FilledInfoCard";

// Presentation page sections
import Counters from "../../pages/Presentation/sections/Counters";
import Information from "../../pages/Main/sections/Information";
import DesignBlocks from "../../pages/Presentation/sections/DesignBlocks";
import Pages from "../../pages/Presentation/sections/Pages";
import Testimonials from "../../pages/Presentation/sections/Testimonials";
import Download from "../../pages/Presentation/sections/Download";

// Presentation page components
import BuiltByDevelopers from "../../pages/Presentation/components/BuiltByDevelopers";

// Routes
import routes from "../../routes";
import mainRoutes from "../../main-routes";
import footerRoutes from "../../main-footer.routes";

// Images
import bgImage from "../../assets/images/bg-main-back.jpg";
import React, {useState} from "react";
import What from "./sections/What";
import Benefits from "./sections/Benefits";
import DataManagement from "./sections/DataManagement";
import Usages from "./sections/Usages";
import Tariffs from "./sections/Tariffs";
import Contact from "./sections/Contact";
import Footer from "./sections/Footer";
import LoginModal from "../../components/modals/LoginModal";

function Main() {
    const [show, setShow] = useState(false);

    const toggleModal = () => setShow(!show);

    return (
    <>
        <LoginModal
            show={show}
            toggleCallback={toggleModal}
        />
      <Navbar
        routes={mainRoutes}
        action={{
          type: "internal",
          callback: toggleModal,
          label: "Войти",
          color: "info",
        }}

      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mt={-6}
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                }, textAlign: 'center'
              })}
            >
                Неизменяемый. Надежный. Безопасный.
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              textAlign="center"
              px={{ xs: 6, lg: 12 }}
              mt={1}
            >
                Неизменяемые логи событий как сервис (ILaaS).
                Применяем блокчейн технологии для хранения логов событий и гарантии их сохранения от несанкционированных изменений.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <What />
        <Benefits />
        <Information />
        <DataManagement />
        <Usages />
        <Tariffs/>
        <Contact />

     </Card>
      <MKBox pt={6} px={1} mt={6}>
        <Footer content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default Main;
